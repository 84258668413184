import React from "react"
import SEO from "../components/Seo"
import Nav from "../components/Nav"

const AboutPage = () => (
  <div>
    <SEO title="About" />
    <Nav />
    
    <div className="biography-container">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius sit amet mattis vulputate enim nulla. Ullamcorper sit amet risus nullam eget felis. Volutpat blandit aliquam etiam erat velit scelerisque in dictum. Nibh praesent tristique magna sit amet purus. Malesuada pellentesque elit eget gravida cum. Felis imperdiet proin fermentum leo vel orci porta. Diam quam nulla porttitor massa id neque. Porta non pulvinar neque laoreet suspendisse interdum consectetur. Odio euismod lacinia at quis risus sed. Ut enim blandit volutpat maecenas. Non diam phasellus vestibulum lorem sed risus ultricies tristique nulla. Feugiat vivamus at augue eget arcu dictum varius duis. Rhoncus mattis rhoncus urna neque viverra justo nec ultrices dui. Sed egestas egestas fringilla phasellus faucibus scelerisque. Aliquam eleifend mi in nulla posuere. Fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque. Aliquet eget sit amet tellus. Vivamus arcu felis bibendum ut tristique et egestas quis.</p>

      <h2>The Contributors</h2>

      <div className="bio">
        <h4>Ahmet</h4>
        <dl>
          <dt>Favourite Games:</dt>
          <dd>Garry's Mod</dd>
        </dl>
      </div>

      <div className="bio">
        <h4>Al</h4>
        <dl>
          <dt>Favourite Games:</dt>
          <dd>Super Mario World, Star Fox</dd>
        </dl>
      </div>

      <div className="bio">
        <h4>Andy</h4>
        <dl>
          <dt>Favourite Games:</dt>
          <dd>Bloodborne</dd>
        </dl>
      </div>

      <div className="bio">
        <h4>Karen</h4>
        <dl>
          <dt>Favourite Games:</dt>
          <dd>Slay the Spire</dd>
        </dl>
      </div>

      <div className="bio">
        <h4>Olly</h4>
        <dl>
          <dt>Favourite Games:</dt>
          <dd>Fallen Order</dd>
        </dl>
      </div>

      <div className="bio">
        <h4>Steve</h4>
        <dl>
          <dt>Favourite Games:</dt>
          <dd>Final Fantasy Anything</dd>
        </dl>
      </div>

      <div className="bio">
        <h4>Tom</h4>
        <dl>
          <dt>Favourite Games:</dt>
          <dd>Jagged Alliance 2</dd>
        </dl>
      </div>
    </div>
  </div>
)

export default AboutPage
